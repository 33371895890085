import * as React from 'react'
import Layout from '../components/layout'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Icon } from '@iconify/react';
import Countdown from 'react-countdown';
//renderer for the launch countdown
const launch_renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return 'Launched';
  } else {
    //console.log("days"+days);
    // Render a countdown
    var years = Math.floor(days / 365.25);
    var months = Math.floor((days - (years * 365.25)) / 30.437);
    days = Math.floor(days - (years * 365.25) - (months * 30));
    var minutes_str = "";
    var seconds_str = "";
    if (minutes < 10) {
      minutes_str = "0" + minutes;
    }
    else {
      minutes_str = "" + minutes;
    }
    if (seconds < 10) {
      seconds_str = "0" + seconds;
    }
    else {
      seconds_str = "" + seconds;
    }
    // quick check to make sure we don't have any wrong-looking values; if we do fudge things. 
    if (months >= 12) {
      months = 11
    }
    if (days >= 31) {
      days = 30
    }

    return <span>{years} years, {months} months, {days} days, {hours}:{minutes_str}:{seconds_str}</span>;
  }
};
const TimelinePage = () => {
  return (
    <Layout pageTitle="Timeline">
      <h1>Timeline</h1>
      <h3>Countdown to launch: <Countdown date='2026-10-01' renderer={launch_renderer} /></h3>

      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="5 November 2021"
          contentStyle={{ background: 'rgb(185,217,235)', color: '#000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(185,217,235)' }}
          iconStyle={{ background: 'rgb(0,59,92)', color: '#fff' }}
          icon={<Icon icon="healthicons:i-certificate-paper-outline" />}
        >
          <h3 className="vertical-timeline-element-title">INCUS Funded</h3>
          <h4 className="vertical-timeline-element-subtitle">Selected as NASA EV-M 3</h4>
          <p>
            In 2021, INCUS was funded as the third NASA Earth Ventures Mission.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="April 2022"
          contentStyle={{ background: 'rgb(185,217,235)', color: '#000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(185,217,235)' }}
          iconStyle={{ background: 'rgb(0,59,92)', color: '#fff' }}
          icon={<Icon icon="fluent-emoji-high-contrast:writing-hand" />}
        >
          <h3 className="vertical-timeline-element-title">Phase A</h3>
          <h4 className="vertical-timeline-element-subtitle">Begins</h4>
          <p>
            INCUS was in Phase A (Concept and Technology Development phase) through March 2023.
          </p>
          <p>
            The purpose of Phase A is to develop a proposed mission/system architecture that is credible and responsive to program expectations, requirements, and constraints on the project, including resources. During Phase A, activities are performed to fully develop a baseline mission concept, begin or assume responsibility for the development of needed technologies, and clarify expected reliance on human elements to achieve full system functionality or autonomous system development. This work, along with interactions with stakeholders, helps mature the mission concept and the program requirements on the project. Systems engineers are heavily involved during this phase in the development and assessment of the architecture and the allocation of requirements to the architecture elements.


          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="March 2023"
          contentStyle={{ background: 'rgb(185,217,235)', color: '#000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(185,217,235)' }}
          iconStyle={{ background: 'rgb(0,59,92)', color: '#fff' }}
          icon={<Icon icon="heroicons-outline:desktop-computer" />}
        >
          <h3 className="vertical-timeline-element-title">Phase B</h3>
          <h4 className="vertical-timeline-element-subtitle">Start</h4>
          <p>
            INCUS is in Phase B through November 2023 (estimated).
          </p>
          <p>
            The purpose of Phase B is for the project team to complete the technology development, engineering prototyping, heritage hardware and software assessments, and other risk-mitigation activities identified in the project Formulation Agreement (FA) and the preliminary design. The project demonstrates that its planning, technical, cost, and schedule baselines developed during Formulation are complete and consistent; that the preliminary design complies with its requirements; that the project is sufficiently mature to begin Phase C; and that the cost and schedule are adequate to enable mission success with acceptable risk. It is at the conclusion of this phase that the project and the Agency commit to accomplishing the project’s objectives for a given cost and schedule.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="October 2023"
          contentStyle={{ background: 'rgb(185,217,235)', color: '#000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(185,217,235)' }}
          iconStyle={{ background: 'rgb(0,59,92)', color: '#fff' }}
          icon={<Icon icon="ant-design:line-chart-outlined" />}
        >
          <h3 className="vertical-timeline-element-title">Phase C</h3>
          <h4 className="vertical-timeline-element-subtitle">Estimated Start</h4>
          <p>
            INCUS starts Phase C in November 2023 (estimated).
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="January 2025"
          contentStyle={{ background: 'rgb(185,217,235)', color: '#000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(185,217,235)' }}
          iconStyle={{ background: 'rgb(0,59,92)', color: '#fff' }}
          icon={<Icon icon="bxs:wrench" />}
        >
          <h3 className="vertical-timeline-element-title">Phase D</h3>
          <h4 className="vertical-timeline-element-subtitle">Estimated Start</h4>
          <p>
            INCUS starts Phase D in January 2025 (estimated).
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2026"
          contentStyle={{ background: 'rgb(185,217,235)', color: '#000' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(185,217,235)' }}
          iconStyle={{ background: 'rgb(0,59,92)', color: '#fff' }}
          icon={<Icon icon="mdi:rocket-launch" />}
        >
          <h3 className="vertical-timeline-element-title">INCUS Launches</h3>
          <p>
            INCUS is currently slated to launch in 2026 (estimated).
          </p>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Layout>
  )
}

export default TimelinePage
